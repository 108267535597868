import Vuex from 'vuex'
import axios from "axios";
import * as buffer from "buffer";
import router from '@/router'
window.Buffer = buffer.Buffer;
export default new Vuex.Store({
    state: {
        user: {
            username:  localStorage.getItem("username"),
            isLoader: false,
            isAdmin: false,
            loggedIn: false,
            token: "",
            refresh: localStorage.getItem("refresh")
        }
    },
    getters: {
        auth(state) {
            state.user.loggedIn = false;
            const ref = localStorage.getItem("refresh");
            if (ref!=undefined && ref!=""){
                state.user.loggedIn = true;
            }
            return state.user
        },
        isAdmin(){
            return this.state.user.isAdmin;
        },
        isLoader(){
            return this.state.user.isLoader;
        },
       getUsername(){
            return this.state.user.username;
        },

    },
    actions: {
        RESET_ALL: (context) => {
                context.user.isAdmin=false;
                context.user.isLoader=false;
                context.user.loggedIn=false;
                context.user.token= "";
                context.user.refresh= "";
                context.user.username= "";
        },
        REFRESH_TOKEN: (context) => {
            return new Promise((resolve, reject) => {
                let opt = {};
                opt.headers = {'X-Refresh': localStorage.getItem("refresh")};
                axios
                    .get(process.env.VUE_APP_API_URL_REFRESH, opt)
                    .then(response => {
                        context.commit('SET_TOKEN', response.data.token);
                        context.commit('SET_ADMIN', true);
                        context.commit('SET_LOGGED_IN', true);
                       // context.commit('SET_IS_LOADER', response.data.email === 'dudnik.v@udtech.global');
                        resolve(response);
                    })
                    .catch(error => {
                        router.push({ name: "login" });
                        reject(error);
                    });
            });
        },
        LOGIN: (context, payload) => {
            return new Promise((resolve,reject) => {
                 axios
                    .post(process.env.VUE_APP_API_URL_LOGIN, "", payload).then(
                    response => {
                        let username;
                        if(response.data.data.email!==""){
                             username = response.data.data.email;
                        }else {
                             username = response.data.data.phone;
                        }
                        if (response.data.data.admin>0) {
                          localStorage.setItem("username", username);
                          context.commit('SET_USERNAME', username);
                          context.commit('SET_TOKEN', response.data.token);
                          context.commit('SET_ADMIN', true);
                          context.commit('SET_LOGGED_IN', true);
                          context.commit('SET_IS_LOADER', response.data.data.email === 'dudnik.v@udtech.global');
                          if (process.env.VUE_APP_API_TYPE === "STAGE") {
                            console.log(response.data.data.email, "==>", response, ">-")
                          }
                        }
                        resolve(response);
                    }
                ).catch((error) => {
                   router.push({ name: "login" });
                   reject(error);
                });
            });
        },
    },
    mutations: {
        SET_TOKEN: (state, payload) => {
            state.user.token = payload;
        },
        SET_USERNAME: (state, payload) => {
            state.user.username = payload;
        },
        SET_ADMIN: (state, payload) => {
            state.user.isAdmin = payload;
        },
        SET_LOGGED_IN: (state, payload) => {
            state.user.loggedIn = payload;
        },
        SET_IS_LOADER: (state, payload) => {
            state.user.isLoader = payload;
        },
    }
})