<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span></span>
		</router-link>
		<button v-if="isAdmin()" class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>


		<ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li><span class="mr-6 mt-3" style="display:inline-flex;">{{getUserName()}}</span></li>
      <li>
				<button v-if="isAdmin()" class="p-link layout-topbar-button" @click="logout">
					<i class="pi pi-sign-out" title="Logout"></i>
					<span>Profile</span>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
import store from "./store";


export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
      logout(){
        localStorage.setItem('refresh',undefined);
        localStorage.setItem('username','');
        store.state.user.isAdmin = false;
        store.state.user.token=undefined;
        store.state.user.refresh=undefined;
        store.state.user.loggedIn = false;
        store.state.user.username = "";
        return window.location="/";
      },
      isAdmin(){
        return store.state.user.isAdmin;
      },
      getUserName(){
     //     console.log("M===", store.state.user)
        return store.state.user.username;
      },
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>
