/* eslint-disable */
<template>
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Users</span>
						<div class="text-900 font-medium text-xl">
              <slot v-bind:stats="stats">
                {{ stats.all }}
              </slot>
            </div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-users text-blue-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{ stats.online }} online </span>
				<span class="text-500">users</span>
        <span class="text-green-500 font-medium"> / V2 </span>
        <span class="text-500">{{ stats.all-this.v3 }}</span>
        <span class="text-green-500 font-medium"> / V3 </span>
        <span class="text-500">{{ this.v3 }}</span>
        <span class="text-green-500 font-medium"> / Trial </span>
        <span class="text-500">{{ this.trial }}</span>

        <div class="flex justify-content-between">
          <div>
            <transition-group name="p-message" tag="div" v-if="infoId == 1">
              <Message v-for="msg of message" :severity="msg.severity" :key="msg.id" @close="message=[]">{{msg.content}}</Message>
            </transition-group>
          </div>
          <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('This sections shows: total number of users; users online; users using API V2 version (PHP); users using API V3 version (GO); users with Trial',1)"></i>
          </div>
        </div>
			</div>
		</div>

		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Stages</span>
            <div class="text-900 font-medium text-xl">
              <slot v-bind:stats="stats">
                {{ stats.stages }}
              </slot>
            </div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-map text-orange-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">&nbsp;</span>
				<span class="text-500">&nbsp;</span>
        <div class="flex justify-content-between">
          <div>
            <transition-group name="p-message" tag="div" v-if="infoId == 2">
              <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
            </transition-group>
          </div>
          <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('Number of stages created in the apps',2)"></i>
          </div>
        </div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Scoresheets</span>
            <div class="text-900 font-medium text-xl">
              <slot v-bind:stats="stats">
                {{ stats.scores_0+stats.scores_1 }}
              </slot>
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-star text-orange-500 text-xl"></i>
          </div>
        </div>

        <span class="text-green-500 font-medium">&nbsp;</span>
        <span class="text-500">&nbsp;</span>
        <div class="flex justify-content-between">
          <div>
            <transition-group name="p-message" tag="div" v-if="infoId == 3">
              <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
            </transition-group>
          </div>
          <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('Number of scoresheets in the apps',3)"></i>
          </div>
        </div>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Sessions</span>
            <div class="text-900 font-medium text-xl">
              <slot v-bind:stats="stats">
                {{ stats.sessions }}
              </slot>
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-database text-orange-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">&nbsp;</span>
        <span class="text-500">&nbsp;</span>
        <div class="flex justify-content-between">
          <div>
            <transition-group name="p-message" tag="div" v-if="infoId == 4">
              <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
            </transition-group>
          </div>
          <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('User activity statistic',4)"></i>
          </div>
        </div>
			</div>
		</div>

	<div class="col-12 xl:col-6">
		<div class="card">
			<div class="flex justify-content-between align-items-center mb-5">
				<h5>User activity statistic</h5>

			</div>
			<ul class="list-none p-0 m-0">
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="text-900 font-medium mr-2 mb-1 md:mb-0">Last 24h</span>
            <div class="mt-1 text-600"><slot v-bind:stats="stats">
              {{ stats.per_day }}
            </slot></div>
					</div>
					<div class="mt-2 md:mt-0 flex align-items-center">
            <slot v-bind:stats="stats">
						<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
							<div class="bg-orange-500 h-full" :style="'width:'+ stats.pc_day+'%'"></div>
						</div>
						<span style="min-width:37px;" class="text-orange-500 ml-3 font-medium">{{stats.pc_day}}%</span>
            </slot>
					</div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
          <div>
            <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Last week</span>
            <div class="mt-1 text-600"><slot v-bind:stats="stats">
              {{ stats.per_week }}
            </slot></div>
          </div>
          <div class="mt-2 md:mt-0 flex align-items-center">
            <slot v-bind:stats="stats">
              <div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
                <div class="bg-orange-500 h-full" :style="'width:'+ stats.pc_week+'%'"></div>
              </div>
              <span style="min-width:37px;" class="text-orange-500 ml-3 font-medium">{{stats.pc_week}}%</span>
            </slot>
          </div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
          <div>
            <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Last month</span>
            <div class="mt-1 text-600"><slot v-bind:stats="stats">
              {{ stats.per_mouth }}
            </slot></div>
          </div>
          <div class="mt-2 md:mt-0 flex align-items-center">
            <slot v-bind:stats="stats">
              <div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
                <div class="bg-orange-500 h-full" :style="'width:'+ stats.pc_mouth+'%'"></div>
              </div>
              <span style="min-width:37px;" class="text-orange-500 ml-3 font-medium">{{stats.pc_mouth}}%</span>
            </slot>
          </div>
				</li>
				<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
          <div>
            <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Last year</span>
            <div class="mt-1 text-600"><slot v-bind:stats="stats">
              {{ stats.per_year }}
            </slot></div>
          </div>
          <div class="mt-2 md:mt-0 flex align-items-center">
            <slot v-bind:stats="stats">
              <div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
                <div class="bg-orange-500 h-full" :style="'width:'+ stats.pc_year+'%'"></div>
              </div>
              <span style="min-width:37px;" class="text-orange-500 ml-3 font-medium">{{stats.pc_year}}%</span>
            </slot>
          </div>
				</li>

			</ul>

      <div class="flex justify-content-between">
        <div>
          <transition-group name="p-message" tag="div" v-if="infoId == 5">
            <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
          </transition-group>
        </div>
        <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
          <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('Number of users using the apps within specified periods of time ',5)"></i>
        </div>
      </div>

    </div>
  </div>
   <div class="col-12 xl:col-6">
    <div class="card">
      <div class="flex justify-content-between align-items-center mb-5">
        <h5>User registration statistic</h5>
      </div>
      <ul class="list-none p-0 m-0">
        <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
          <div>
            <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Last 24h</span>
            <div class="mt-1 text-600"><slot v-bind:stats="stats">
              {{ stats.rper_day }}
            </slot></div>
          </div>
          <div class="mt-2 md:mt-0 flex align-items-center">
            <slot v-bind:stats="stats">
              <div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
                <div class="bg-orange-500 h-full" :style="'width:'+ stats.rpc_day+'%'"></div>
              </div>
              <span style="min-width:37px;" class="text-orange-500 ml-3 font-medium">{{stats.rpc_day}}%</span>
            </slot>
          </div>
        </li>
        <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
          <div>
            <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Last week</span>
            <div class="mt-1 text-600"><slot v-bind:stats="stats">
              {{ stats.rper_week }}
            </slot></div>
          </div>
          <div class="mt-2 md:mt-0 flex align-items-center">
            <slot v-bind:stats="stats">
              <div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
                <div class="bg-orange-500 h-full" :style="'width:'+ stats.rpc_week+'%'"></div>
              </div>
              <span style="min-width:37px;" class="text-orange-500 ml-3 font-medium">{{stats.rpc_week}}%</span>
            </slot>
          </div>
        </li>
        <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
          <div>
            <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Last month</span>
            <div class="mt-1 text-600"><slot v-bind:stats="stats">
              {{ stats.rper_mouth }}
            </slot></div>
          </div>
          <div class="mt-2 md:mt-0 flex align-items-center">
            <slot v-bind:stats="stats">
              <div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
                <div class="bg-orange-500 h-full" :style="'width:'+ stats.rpc_mouth+'%'"></div>
              </div>
              <span style="min-width:37px;" class="text-orange-500 ml-3 font-medium">{{stats.rpc_mouth}}%</span>
            </slot>
          </div>
        </li>
        <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
          <div>
            <span class="text-900 font-medium mr-2 mb-1 md:mb-0">Last year</span>
            <div class="mt-1 text-600"><slot v-bind:stats="stats">
              {{ stats.rper_year }}
            </slot></div>
          </div>
          <div class="mt-2 md:mt-0 flex align-items-center">
            <slot v-bind:stats="stats">
              <div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style="height:8px">
                <div class="bg-orange-500 h-full" :style="'width:'+ stats.rpc_year+'%'"></div>
              </div>
              <span style="min-width:37px;" class="text-orange-500 ml-3 font-medium">{{stats.rpc_year}}%</span>
            </slot>
          </div>
        </li>

      </ul>
      <div class="flex justify-content-between">
        <div>
          <transition-group name="p-message" tag="div" v-if="infoId == 6">
            <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
          </transition-group>
        </div>
        <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
          <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('Number of users registered in the apps within specified periods of time',6)"></i>
        </div>
      </div>
    </div>
   </div>

	</div>


  <TabView class="mb-4">
    <TabPanel header="User registration">
        <div class="card grid p-fluid">
          <div class="col-12 lg:col-10">
            <Chart type="bar" :data="barData" :options="options"/>
          </div>
          <div class="col-2 lg:col-2 bg-blue-50 border-round">
            <div class="flex flex-column align-items-center gap-3">
              <label>Search by country</label>
              <AutoComplete style="width:90%;" @item-select="this.getStatIntervalUser()" @item-unselect="this.getStatIntervalUser()" placeholder="Search by country" id="statUser" :dropdown="true" :multiple="true" v-model="selectedAutoValueUser" :suggestions="autoFilteredValueUser" @complete="searchCountryUser($event)" field="name"/>
              <label>Time range </label>
              <Dropdown @change="this.getStatIntervalUser()"  style="width:90%;" id="statRangeUser" v-model="statRangeUser" :options="range" optionLabel="label" placeholder="Select range">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
              <label>Starting date</label>
              <Calendar placeholder="From" style="width:90%;"
                        :max-date="this.calendarValueEndUser?new Date(calendarValueEndUser):new Date(Date.now())"
                        @date-select="this.getStatIntervalUser()"
                        :showIcon="true" :showButtonBar="true"
                        v-model="calendarValueStartUser"></Calendar>
              <label>End date</label>
              <Calendar placeholder="To"  style="width:90%;"
                        :max-date="new Date(Date.now())"
                        :min-date="new Date(this.calendarValueStartUser)"
                        @date-select="this.getStatIntervalUser()"
                        :showIcon="true" :showButtonBar="true"
                        v-model="calendarValueEndUser"></Calendar>
              <label>Reset</label>
              <Button icon="pi pi-replay" @click="resetUser()" class="p-button-rounded mr-2 mb-2" />
            </div>
          </div>
        </div>
        <div class="flex justify-content-between">
          <div>
            <transition-group name="p-message" tag="div" v-if="infoId == 7">
              <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
            </transition-group>
          </div>
          <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('Graphic illustrates user registration from different registration options (email, Google, Apple) including the total number of registered users.',7)"></i>
          </div>
        </div>

    </TabPanel>
    <TabPanel header="Free shooting statistics">
        <div class="card grid p-fluid">
          <div class="col-12 lg:col-10">
            <Chart type="bar" :data="barDataStats[0]" />
          </div>
          <div class="col-2 lg:col-2 bg-blue-50 border-round">
            <div class="flex flex-column align-items-center gap-3">
              <label>Search by country</label>
              <AutoComplete style="width:90%;" @item-select="this.getStatInterval(0)" @item-unselect="this.getStatInterval(0)" placeholder="Search by country" id="dd" :dropdown="true" :multiple="true" v-model="selectedAutoValue[0]" :suggestions="autoFilteredValue" @complete="searchCountry($event)" field="name"/>
              <label>Time range </label>
              <Dropdown @change="this.getStatInterval(0)"  style="width:90%;" id="statRange" v-model="statRange[0]" :options="range" optionLabel="label" placeholder="Select range">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
              <label>Starting date</label>
              <Calendar placeholder="From" style="width:90%;"
                        :max-date="this.calendarValueEnd[0]?new Date(this.calendarValueEnd[0]):new Date(Date.now())"
                        @date-select="this.getStatInterval(0)"
                        :showIcon="true" :showButtonBar="true"
                        v-model="calendarValueStart[0]"></Calendar>
              <label>End date</label>
              <Calendar placeholder="To" style="width:90%;"
                        :max-date="new Date(Date.now())"
                        :min-date="new Date(this.calendarValueStart[0])"
                        @date-select="this.getStatInterval(0)"
                        :showIcon="true" :showButtonBar="true"
                        v-model="calendarValueEnd[0]"></Calendar>
              <label>Reset</label>
              <Button icon="pi pi-replay" @click="resetStat(0)" class="p-button-rounded mr-2 mb-2" />
            </div>
          </div>
        </div>
    </TabPanel>
    <TabPanel header="Dry Practice statistics">
        <div class="card grid p-fluid">
          <div class="col-12 lg:col-10">
            <Chart type="bar" :data="barDataStats[1]" />
          </div>
          <div class="col-2 lg:col-2 bg-blue-50 border-round">
            <div class="flex flex-column align-items-center gap-3">
              <label>Search by country</label>
              <AutoComplete style="width:90%;" @item-select="this.getStatInterval(1)" @item-unselect="this.getStatInterval(1)" placeholder="Search by country" id="dd" :dropdown="true" :multiple="true" v-model="selectedAutoValue[1]" :suggestions="autoFilteredValue" @complete="searchCountry($event)" field="name"/>
              <label>Time range </label>
              <Dropdown @change="this.getStatInterval(1)"  style="width:90%;" id="statRange" v-model="statRange[1]" :options="range" optionLabel="label" placeholder="Select range">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
              <label>Starting date</label>
              <Calendar placeholder="From" style="width:90%;"
                        :max-date="this.calendarValueEnd[1]?new Date(this.calendarValueEnd[1]):new Date(Date.now())"
                        @date-select="this.getStatInterval(1)" :showIcon="true" :showButtonBar="true"
                        v-model="calendarValueStart[1]"></Calendar>
              <label>End date</label>
              <Calendar placeholder="To" style="width:90%;"
                        :max-date="this.calendarValueStart[1] ? new Date(Date.now()) : new Date(this.calendarValueStart[1])"
                        :min-date="new Date(this.calendarValueStart[1])"
                        @date-select="this.getStatInterval(1)" :showIcon="true"
                        :showButtonBar="true" v-model="calendarValueEnd[1]"></Calendar>
              <label>Reset</label>
              <Button icon="pi pi-replay" @click="resetStat(1)" class="p-button-rounded mr-2 mb-2" />
            </div>
          </div>
        </div>
    </TabPanel>
    <TabPanel header="Challenge stats">
        <div class="card grid p-fluid">
          <div class="col-12 lg:col-10">
            <Chart type="bar" :data="barDataStats[2]" />
          </div>
          <div class="col-2 lg:col-2 bg-blue-50 border-round">
            <div class="flex flex-column align-items-center gap-3">
              <label>Search by country</label>
              <AutoComplete style="width:90%;" @item-select="this.getStatInterval(2)"
                            @item-unselect="this.getStatInterval(2)"
                            placeholder="Search by country" id="dd" :dropdown="true"
                            :multiple="true" v-model="selectedAutoValue[2]" :suggestions="autoFilteredValue"
                            @complete="searchCountry($event)" field="name"/>
              <label>Time range </label>
              <Dropdown @change="this.getStatInterval(2)"  style="width:90%;" id="statRange"
                        v-model="statRange[2]" :options="range" optionLabel="label" placeholder="Select range">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
              <label>Starting date</label>
              <Calendar placeholder="From" style="width:90%;"
                        :max-date="this.calendarValueEnd[2]?new Date(this.calendarValueEnd[2]):new Date(Date.now())"
                        @date-select="this.getStatInterval(2)" :showIcon="true"
                        :showButtonBar="true" v-model="calendarValueStart[2]"></Calendar>
              <label>End date</label>
              <Calendar placeholder="To" style="width:90%;"
                        :max-date="new Date(Date.now())"
                        :min-date="new Date(this.calendarValueStart[2])"
                        @date-select="this.getStatInterval(2)" :showIcon="true"
                        :showButtonBar="true" v-model="calendarValueEnd[2]"></Calendar>
              <label>Reset</label>
              <Button icon="pi pi-replay" @click="resetStat(2)" class="p-button-rounded mr-2 mb-2" />
            </div>
          </div>
        </div>
    </TabPanel>
    <TabPanel header="Shooting stats">
        <div class="card grid p-fluid">
          <div class="col-12 lg:col-10">
            <Chart type="bar" :data="barDataStats[3]" />
          </div>
          <div class="col-2 lg:col-2 bg-blue-50 border-round">
            <div class="flex flex-column align-items-center gap-3">
              <label>Search by country</label>
              <AutoComplete style="width:90%;" :max-date="new Date(Date.now())" @item-select="this.getStatInterval(3)" @item-unselect="this.getStatInterval(3)" placeholder="Search by country" id="dd" :dropdown="true" :multiple="true" v-model="selectedAutoValue[3]" :suggestions="autoFilteredValue" @complete="searchCountry($event)" field="name"/>
              <label>Time range </label>
              <Dropdown @change="this.getStatInterval(3)"
                        style="width:90%;" id="statRange"
                        v-model="statRange[3]" :options="range" optionLabel="label" placeholder="Select range">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.label">
                    <span>{{slotProps.value.label}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
              <label>Starting date</label>
              <Calendar placeholder="From" style="width:90%;"
                        :max-date="this.calendarValueEnd[3]?new Date(this.calendarValueEnd[3]):new Date(Date.now())"
                        @date-select="this.getStatInterval(3)"
                        :showIcon="true"
                        :showButtonBar="true"
                        v-model="calendarValueStart[3]"></Calendar>
              <label>End date</label>
              <Calendar placeholder="To" style="width:90%;"
                        :max-date="new Date(Date.now())"
                        :min-date="new Date(this.calendarValueStart[3])"
                        @date-select="this.getStatInterval(3)"
                        :showIcon="true"
                        :showButtonBar="true"
                        v-model="calendarValueEnd[3]"></Calendar>
              <label>Reset</label>
              <Button icon="pi pi-replay" @click="resetStat(3)" class="p-button-rounded mr-2 mb-2" />
            </div>
          </div>
        </div>
    </TabPanel>
    <TabPanel header="Stages">
      <div class="card grid p-fluid">
        <div class="col-12 lg:col-10">
          <Chart type="bar" :data="barDataStats[4]" />
        </div>
        <div class="col-2 lg:col-2 bg-blue-50 border-round">
          <div class="flex flex-column align-items-center gap-3">
            <label>Search by country</label>
            <AutoComplete style="width:90%;" @item-select="this.getStatInterval(4)" @item-unselect="this.getStatInterval(4)" placeholder="Search by country" id="dd" :dropdown="true" :multiple="true" v-model="selectedAutoValue[4]" :suggestions="autoFilteredValue" @complete="searchCountry($event)" field="name"/>
            <label>Time range </label>
            <Dropdown @change="this.getStatInterval(4)"  style="width:90%;" id="statRange" v-model="statRange[4]" :options="range" optionLabel="label" placeholder="Select range">
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.label">
                  <span>{{slotProps.value.label}}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.label">
                  <span>{{slotProps.value.label}}</span>
                </div>
                <span v-else>
									{{slotProps.placeholder}}
								</span>
              </template>
            </Dropdown>
            <label>Starting date</label>
            <Calendar placeholder="From" style="width:90%;"
                      :max-date="this.calendarValueEnd[4]?new Date(this.calendarValueEnd[4]):new Date(Date.now())"
                      @date-select="this.getStatInterval(4)" :showIcon="true"
                      :showButtonBar="true" v-model="calendarValueStart[4]"></Calendar>
            <label>End date</label>
            <Calendar placeholder="To" style="width:90%;"
                      :max-date="new Date(Date.now())"
                      :min-date="new Date(this.calendarValueStart[4])"
                      @date-select="this.getStatInterval(4)"
                      :showIcon="true"
                      :showButtonBar="true"
                      v-model="calendarValueEnd[4]"></Calendar>
            <label>Reset</label>
            <Button icon="pi pi-replay" @click="resetStat(4)" class="p-button-rounded mr-2 mb-2" />
          </div>
        </div>
      </div>
    </TabPanel>
    <TabPanel header="Scores">
      <div class="card grid p-fluid">
        <div class="col-12 lg:col-10">
          <Chart type="bar" :data="barDataStats[5]" />
        </div>
        <div class="col-2 lg:col-2 bg-blue-50 border-round">
          <div class="flex flex-column align-items-center gap-3">
            <label>Search by country</label>
            <AutoComplete style="width:90%;" @item-select="this.getStatInterval(5)" @item-unselect="this.getStatInterval(5)" placeholder="Search by country" id="dd" :dropdown="true" :multiple="true" v-model="selectedAutoValue[5]" :suggestions="autoFilteredValue" @complete="searchCountry($event)" field="name"/>
            <label>Time range </label>
            <Dropdown @change="this.getStatInterval(5)"  style="width:90%;" id="statRange" v-model="statRange[5]" :options="range" optionLabel="label" placeholder="Select range">
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.label">
                  <span>{{slotProps.value.label}}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.label">
                  <span>{{slotProps.value.label}}</span>
                </div>
                <span v-else>
									{{slotProps.placeholder}}
								</span>
              </template>
            </Dropdown>
            <label>Starting date</label>
            <Calendar placeholder="From" style="width:90%;"
                      :max-date="this.calendarValueEnd[5]?new Date(this.calendarValueEnd[5]):new Date(Date.now())"
                      @date-select="this.getStatInterval(5)" :showIcon="true"
                      :showButtonBar="true" v-model="calendarValueStart[5]"></Calendar>
            <label>End date</label>
            <Calendar placeholder="To" style="width:90%;"
                      :max-date="new Date(Date.now())"
                      :min-date="new Date(this.calendarValueStart[5])"
                      @date-select="this.getStatInterval(5)"
                      :showIcon="true" :showButtonBar="true" v-model="calendarValueEnd[5]"></Calendar>
            <label>Reset</label>
            <Button icon="pi pi-replay" @click="resetStat(5)" class="p-button-rounded mr-2 mb-2" />
          </div>
        </div>
      </div>
    </TabPanel>
    <TabPanel header="Emails sync stats">
        <div class="card grid p-fluid">
          <div class="col-12 lg:col-10">
            <Chart type="bar" :data="barDataStats[6]" />
          </div>
          <div class="col-2 lg:col-2 bg-blue-50 border-round">
            <div class="flex flex-column align-items-center gap-3">
              <label>Starting date</label>
              <Calendar placeholder="From" style="width:90%;"
                        :max-date="this.calendarValueEnd[6]?new Date(this.calendarValueEnd[6]):new Date(Date.now())"
                        @date-select="this.getStatInterval(6)"
                        :showIcon="true" :showButtonBar="true"
                        v-model="calendarValueStart[6]"></Calendar>
              <label>End date</label>
              <Calendar placeholder="To" style="width:90%;"
                        :max-date="new Date(Date.now())"
                        :min-date="new Date(this.calendarValueStart[6])"
                        @date-select="this.getStatInterval(6)" :showIcon="true"
                        :showButtonBar="true" v-model="calendarValueEnd[6]"></Calendar>
              <label>Reset</label>
              <Button icon="pi pi-replay" @click="resetStat(6)" class="p-button-rounded mr-2 mb-2" />
            </div>
          </div>
        </div>

    </TabPanel>

  </TabView>

  <div class="grid p-fluid mb-3">
    <div class="col-12 lg:col-6">
  <TabView>
    <TabPanel header="Firmware">
          <div class="flex flex-column align-items-center">
            <Chart type="pie" :data="fwData" style="width: 50%" />
            <div class="flex justify-content-between" style="min-height:100px;">
              <div>
                <transition-group name="p-message" tag="div" v-if="infoId == 8">
                  <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
                </transition-group>
              </div>
              <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
                <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('Firmware versions users use',8)"></i>
              </div>
            </div>
          </div>
    </TabPanel>
    <TabPanel header="OS Chart">
      <div class="flex flex-column align-items-center">
        <Chart type="pie" :data="pieData" style="width: 50%" />
        <div class="flex justify-content-between" style="min-height:100px;">
          <div>
            <transition-group name="p-message" tag="div" v-if="infoId == 9">
              <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
            </transition-group>
          </div>
          <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('Shows number of iOS and Android users',9)"></i>
          </div>
        </div>
      </div>
    </TabPanel>
    <TabPanel v-if="showUserChart" header="Users Chart">
      <div class="flex flex-column align-items-center">
        <Chart type="pie" :data="pieDataUsers" style="width: 50%" />
        <div class="flex justify-content-between" style="min-height:100px;">
          <div>
            <transition-group name="p-message" tag="div" v-if="infoId == 10">
              <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
            </transition-group>
          </div>
          <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('Shows users who have free accounts, paid accounts and promo',10)"></i>
          </div>
        </div>
      </div>
    </TabPanel>
  </TabView>
    </div>
    <div class="col-12 lg:col-6">
<TabView>
  <TabPanel header="Latest newsletters reading">
    <div class="flex flex-column align-items-center">
    <Chart type="pie" :data="pieDataRead" style="width: 50%" />
    <div class="col-12 lg:col-6" style="min-height:100px;">
      <div class="flex flex-column align-items-center">

        <Dropdown @change="this.getMailCrcData()" style="width:100%;" id="LetterRange" v-model="autoValueLetter" :options="autoFilteredValueLetter" optionLabel="label" placeholder="Select range">
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.label">
              <span>{{slotProps.value.label}}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.label">
              <span>{{slotProps.value.label}}</span>
            </div>
          </template>
        </Dropdown>

        <div class="flex justify-content-between">
          <div>
            <transition-group name="p-message" tag="div" v-if="infoId == 11">
              <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
            </transition-group>
          </div>
          <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('This diagram illustrates the number of users read the newsletters. The specific newsletter can be selected in the drop-down list below. \n',11)"></i>
          </div>
        </div>

      </div>
    </div>
    </div>
  </TabPanel>
  <TabPanel header="Last unsubscribe">
    <div class="flex flex-column align-items-center">
    <Chart type="pie" :data="pieDataUnsubscribe" style="width: 50%" />
    <div class="col-12 lg:col-6">
      <div class="flex flex-column align-items-center" style="min-height:86px;">

        <Dropdown @change="this.getCrcUnsubscribeStats()" style="width:100%;" id="LetterRange" v-model="autoValueLetterUns" :options="autoFilteredValueLetter" optionLabel="label" placeholder="Select range">
          <template #value="slotProps">
            <div v-if="slotProps.value && slotProps.value.label">
              <span>{{slotProps.value.label}}</span>
            </div>
            <div v-else-if="slotProps.value && !slotProps.value.label">
              <span>{{slotProps.value.label}}</span>
            </div>
          </template>
        </Dropdown>


        <div class="flex justify-content-between">
          <div>
            <transition-group name="p-message" tag="div" v-if="infoId == 12">
              <Message v-for="msg of message" :severity="msg.severity" :key="msg.content" @close="message=[]">{{msg.content}}</Message>
            </transition-group>
          </div>
          <div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
            <i class="pi pi-question-circle text-xl p-button-info text-blue-900" @click="addMessage('The diagram illustrates the number of users unsubscribed from the newsletter. The specific newsletter can be selected in the drop-down list below.\n',12)"></i>
          </div>
        </div>

      </div>
      </div>
    </div>
  </TabPanel>
</TabView>
    </div>

  </div>

  <div class="grid p-fluid">

    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card" style="min-height:1060px;">
        <DataTable @mouseover="overTable" @mouseout="outTable" :row-hover="true" :value="geo" :rows="16" :page-link-size="2" :paginator="true" responsiveLayout="scroll">
          <Column field="country" header="Country" :sortable="true" style="width:95%" >
            <template #body="slotProps">
              <span v-if="slotProps.data.code"> <img :id="slotProps.data.code+'_IMG'" src="../assets/demo/flags/flag_placeholder.png" :class="'flag flag-'+slotProps.data.code.toLowerCase()" width="30" /></span>
              {{formatCurrency(slotProps.data.country)}}
            </template>
          </Column>
          <Column field="count" header="" :sortable="true" style="width:5%">
            <template #body="slotProps">
              {{slotProps.data.count}}
            </template>
          </Column>

        </DataTable>
      </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-9">
      <div class="card p-fluid">
        <div class="grid p-fluid ">
          <div class="col-12 lg:col-6">
            <div class="flex flex-column align-items-center">
              <AutoComplete style="width:50%;" @item-select="this.getStatIntervalMap()" @item-unselect="this.getStatIntervalMap()" placeholder="Search by country" id="statMap" :dropdown="true" :multiple="true" v-model="selectedAutoValueMap" :suggestions="autoFilteredValueMap" @complete="searchMap($event)" field="name"/>

            </div>
          </div>
          <div class="col-10 lg:col-5">
            <div class="flex flex-column align-items-center">
              <Calendar placeholder="From"  :max-date="new Date(Date.now())" @date-select="this.getStatIntervalMap()" :showIcon="true" :showButtonBar="true" v-model="calendarValueStartMap"></Calendar>
              <Calendar placeholder="To"  :max-date="new Date(Date.now())"  :min-date="new Date(this.calendarValueStartMap)" @date-select="this.getStatIntervalMap()" :showIcon="true" :showButtonBar="true" v-model="calendarValueEndMap"></Calendar>
            </div>
          </div>
          <div class="col-2 lg:col-1">
            <Button icon="pi pi-replay" @click="resetMap()" class="p-button-rounded mr-2 mb-2" />
          </div>
        </div>
      </div>
      <div class="card p-fluid" style="min-height:900px;">
        <h5>Users by country <span v-if="this.other>0">({{this.other}} unknown)</span></h5>
        <MapChart
            ref="map"
            @mouseover="mapOver"
            @mouseout="mapOut"
            :countryData="geoData"
            highColor="#454545"
            lowColor="#e9e9e9"
            countryStrokeColor="#909090"
            defaultCountryFillColor="#F5FCFF"
        />
      </div>
    </div>
  </div>

</template>

<script>
import DashboardService from '../service/DashboardService';
import MapChart from "vue-map-chart";
export default {
	data() {
		return {
      showUserChart: false,
      infoId:0,
      messCnt:0,
       message: [],
       colorArray : ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
      keyType:0,
      v3:0,
      trial:0,
      statRange:[null, null],
      mapsRange:{label: "All", value: 2},
      statRangeUser:null,
      geoMaps:null,
      range:[
        {label: "Days", value: ""},
        {label: "Weeks", value: "weeks"},
        {label: "Months", value: "months"},
        {label: "Years", value: "years"},
      ],
      rangeMap:[
        {label: "Registered", value: 0},
        {label: "Not registered", value: 1},
        {label: "All", value: 2},
      ],
      chartLabels:[],
      chartLabels1:[],
      chartData: [],
      calendarValueStart: [],
      calendarValueEnd: [],
      autoValue: [],
      selectedAutoValue: [],
      autoFilteredValue: [],

      calendarValueStartUser: null,
      calendarValueEndUser: null,
      autoValueUser: [],
      selectedAutoValueUser: null,
      autoFilteredValueUser: [],

      autoValueLetter: null,
      autoValueLetterUns: null,
      selectedAutoValueLetter: null,
      autoFilteredValueLetter: [],

      calendarValueStartMap: null,
      calendarValueEndMap: null,
      autoValueMap: [],
      selectedAutoValueMap: null,
      autoFilteredValueMap: [],

      horizontalOptions: {
        responsive: true,
        maintainAspectRatio: true,
        indexAxis: 'x',
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        }
      },
      geo:[],
      timer: '',
			products: null,
      dashboardService: null,
      stats: [],
      statsChart:{},
      statsLine:{},
      pieData: {
        labels: ['iOS','Android'],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: [
              "#4BC0C0",
              "#E7E9ED",
            ],
            hoverBackgroundColor: [
              "#4BC0C0",
              "#E7E9ED",
            ]
          }
        ]
      },

      fwData: {
        labels: ['',''],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: [
              "#4BC0C0",
              "#E7E9ED",
            ],
            hoverBackgroundColor: [
              "#4BC0C0",
              "#E7E9ED",
            ]
          }
        ]
      },
      pieDataUsers: {
        labels: ['Free','Paid', 'Promo'],
        datasets: [
          {
            data: [300, 500],
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#4BC0C0",
            ],
            hoverBackgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#4BC0C0",
            ],

          }
        ]
      },

      pieDataRead: {
        labels: ['Read','Unread'],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: [
              "#4BC0C0",
              "#E7E9ED",
            ],
            hoverBackgroundColor: [
              "#4BC0C0",
              "#E7E9ED",
            ]
          }
        ]
      },
      pieDataUnsubscribe: {
        labels: ['Unsubscribed','Other'],
        datasets: [
          {
            data: [300, 500],
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
            ],
            hoverBackgroundColor: [
              "#FF6384",
              "#36A2EB",
            ]
          }
        ]
      },


      items: [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ],
      geoData:[],
      other:0,
      barDataStats: [{datasets:[]},{datasets:[]},{datasets:[]},{datasets:[]},{datasets:[]},{datasets:[]},{datasets:[]}],

      barData:{},
       months:[ "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December" ],
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      },
		}

	},
	productService: null,
	created() {
		this.dashboardService= new DashboardService();
   // this.timer = setInterval(this.fetchData, 30000);
	},
	mounted() {
		this.fetchData();
	},
	methods: {
    round(number){
      if(number.toFixed(2) == number){
        return +number.toFixed(2);
      }
      return number.toFixed(2);
    },
    addMessage(mess,id) {
      this.infoId = id;
      this.message = [{severity: 'info', detail: 'Info Message', content:mess, id: id}]
    },
    //getCrcUnsubscribeStats
    getCrcUnsubscribeStats(){
      this.dashboardService.getCrcUnsubscribeStats(this.autoValueLetterUns.code).then(data => {
        if (data.data) {
          this.pieDataUnsubscribe.datasets[0].data=[data.data.unsubscribe, data.data.cnt - data.data.unsubscribe];
        }
      })
      console.log(this.autoValueLetterUns.code)
    },
    getMailCrcData(){
     this.dashboardService.getCrcStats(this.autoValueLetter.code).then(data => {
       if (data.data) {
         this.pieDataRead.datasets[0].data=[data.data.read, data.data.cnt - data.data.read];
       }
     })
      console.log(this.autoValueLetter.code)
    },
    resetMap(){
      this.calendarValueStartMap= null;
      this.calendarValueEndMap= null;
      this.selectedAutoValueMap= null;
      this.autoFilteredValueMap=[];
      this.getStatIntervalMap();

    },
    resetUser(){
      this.statRangeUser={label: "Months", value: "months"};
          this.calendarValueStartUser= null;
          this.calendarValueEndUser= null;
          this.selectedAutoValueUser= null;
          this.autoFilteredValueUser=[];
          this.getStatIntervalUser();

    },
    resetStat(i){
      this.statRange=[{label: "Days", value: "days"}, {label: "Days", value: "days"}];
      this.calendarValueStart[i]= null;
      this.calendarValueEnd[i]= null;
      this.selectedAutoValue[i]= null;
      this.autoFilteredValue[i]=[];
      this.getStatInterval(i);
    },
    getStatInterval(i) {
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf());
        if(Number.isInteger(days)) {
          date.setDate(date.getDate() + days);
        }
        return date;
      };

      let ids = [];
      if (this.selectedAutoValue[i]) {
        this.selectedAutoValue[i].forEach(function (item) {
          ids.push(item.id);
        });
      }

      const r = {};
      if (ids.length > 0) {
        r["countries"] = ids;
      }

      if (this.calendarValueStart[i] != null && this.calendarValueStart[i] instanceof Date) {
        r["date_begin"] = this.calendarValueStart[i].addDays(1).toISOString().slice(0, 10).replace('T', ' ');
      }
      if (this.calendarValueEnd[i] != null && this.calendarValueEnd[i] instanceof Date) {
        r["date_end"] = this.calendarValueEnd[i].addDays(2).toISOString().slice(0, 10).replace('T', ' ');
      }
      if(this.range && this.statRange[i]) {
        r["range"] = this.statRange[i].value;
      }
        this.dashboardService.getStatsInterval(r).then(data => {
         this.drawChart(data,i)
        })

    },

    getStatIntervalMap() {
      let ids = [];
      if (this.selectedAutoValueMap) {
        this.selectedAutoValueMap.forEach(function (item) {
          ids.push(item.id);
        });
      }
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf());
        if(Number.isInteger(days)) {
          date.setDate(date.getDate() + days);
        }
        return date;
      };
      const r = {};
      if (ids.length > 0) {
        r["countries"] = ids;
      }
      if (this.calendarValueStartMap != null) {
        r["date_begin"] = this.calendarValueStartMap.addDays(1).toISOString().slice(0, 10).replace('T', ' ');
      }

      if (this.calendarValueEndMap != null) {
        r["date_end"] = this.calendarValueEndMap.addDays(1).toISOString().slice(0, 10).replace('T', ' ');
      }
      this.dashboardService.getGeoStats(r).then(data => {
        if(data.data!=null) {
          this.geoMaps = data.data;
        }
        this.getStatIntervalMaps()
      })

    },
    getStatIntervalMaps() {
      this.keyType = this.mapsRange.value;
      let other = 0;
      let gd={};
      let av = [];
      let d = [];
        this.geo = d;
        this.other = other;
        this.geoData = gd;
      let all=[];

      if(this.keyType < 2){
        all.push(this.geoMaps[this.keyType]);
      }else{
        all = JSON.parse(JSON.stringify(this.geoMaps));
      }
      all.forEach(function (data) {
        if(data!=null) {
          data.forEach(function (item) {
            if (item.code) {
              //{"id":5,"country":"Azerbaijan","code":"AZ","count":2}
              let key = Object.keys(d).find(key => d[key].id === item.id);
              if(key == undefined) {
                d.push(item);
              }else{
                d[key].count+=item.count;
              }
            }

            if (item.id == 0 || item.id == null) {
              other += item.count;
            } else {
              let key1 = Object.keys(av).find(key => av[key].id === item.id);
              if(key1 == undefined) {
                av.push({"name": item.country, "code": item.code, "id": item.id})
              }

                if(item.count>=0) {
                  if(gd[item.code] == undefined){
                    gd[item.code] = item.count;
                  }else{
                    gd[item.code] += item.count;
                  }

                }
            }
          });
        }
      });
      if (Object.keys(this.geoData).length == 1) {
          this.geoData['X'] = 0;
      }
      this.geo = d.sort((a, b) => {
        return a.count < b.count;
      });

      this.other = other;

    },
    getStatIntervalUser() {
      let ids = [];
      if (this.selectedAutoValueUser) {
        this.selectedAutoValueUser.forEach(function (item) {
          ids.push(item.id);
        });
      }
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf());
        if(Number.isInteger(days)) {
          date.setDate(date.getDate() + days);
        }
        return date;
      };
      const r = {};
      if (ids.length > 0) {
        r["countries"] = ids;
      }
      if (this.calendarValueStartUser != null) {
        r["date_begin"] = this.calendarValueStartUser.addDays(1).toISOString().slice(0, 10).replace('T', ' ');
      }

      if (this.calendarValueEndUser != null) {
        r["date_end"] = this.calendarValueEndUser.addDays(0).toISOString().slice(0, 10).replace('T', ' ');
      }
      if(this.statRangeUser) {
        r["range"] = this.statRangeUser.value;
      }
      r["only_users"] = 1;

      this.dashboardService.getStatsInterval(r).then(data => {
        this.drawChartUser(data)
      })
    },
    searchCountry(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredValue = [...this.autoValue];
        }
        else {
          this.autoFilteredValue = this.autoValue.filter((country) => {
            if(country.name!=null) {
              return country.name.toLowerCase().startsWith(event.query.toLowerCase());
            }
          });
        }
      }, 250);
    },

    searchMap(event) {
       setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredValueMap = [...this.autoValueMap];
        }
        else {
          this.autoFilteredValueMap = this.autoValueMap.filter((country) => {
            if(country.name!=null) {
              return country.name.toLowerCase().startsWith(event.query.toLowerCase());
            }
          });
        }
      }, 250);
    },

    searchCountryUser(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredValueUser = [...this.autoValueUser];
        }
        else {
          this.autoFilteredValueUser = this.autoValueUser.filter((country) => {
            if(country.name!=null) {
              return country.name.toLowerCase().startsWith(event.query.toLowerCase());
            }
          });
        }
      }, 250);
    },
    overTable(e){
      if (e.target!=undefined && e.target.childNodes!=undefined && e.target.childNodes.length>2) {
        if(e.target.childNodes[2].childNodes.length>0) {
         const tag = e.target.childNodes[2].childNodes[0].id;
         if(tag!=undefined){
         const  stTag = tag.substr(0,tag.length-4);
         if(stTag) {
           const el = window.document.getElementById(stTag)
           if (el) {
             el.style = 'fill:#36A2EB;';
           }
          }
         }
        }
      }
    },
    outTable(e){
      if (e.target!=undefined && e.target.childNodes!=undefined && e.target.childNodes.length>2) {
        if(e.target.childNodes[2].firstElementChild) {
          const tag = e.target.childNodes[2].firstElementChild.id;
          if(tag!=undefined){
          const  stTag = tag.substr(0,tag.length-4);
          if(stTag) {
            const el = window.document.getElementById(stTag)
            if (el) {
              el.style = '';
            }
          }
          }
        }
      }
    },
    mapOver(e){
      if(e.target) {
        e.target.style="fill:#36A2EB;"
        e.target.classList.remove("land")
        e.target.classList.add("stoke");
      }
    },
    mapOut(e){
      if(e.target) {
        e.target.style="";
        e.target.classList.remove("stoke")
        e.target.classList.add("land");
      }
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF'.split('');
      let color = '#';
      for (let i = 0; i < 6; i++ ) {
      color += letters[Math.floor(Math.random() * 16)];
    }
     return color;
    },
		formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		},
    drawChart(data,i){
      for (const [key] of Object.entries(this.barDataStats[i].datasets)) {
        this.barDataStats[i].datasets[key].data=[];
      }
     // console.log( this.barDataStats[i].datasets["data"])
      //this.barDataStats[i].datasets.data = [];
      let chartLabels=[];
      let lab = {};
      let name = [];
      let tmp = {};
      if (data.data !=null && data.data!=undefined) {
        for (const [value] of Object.entries(data.data)) {
          if (data.data[value] == null) {
             continue;
          }
          const kn = this.barDataStats[i].datasets.findIndex(x => x.key === value);
          if (kn<0){
            continue
          }

          name.push(value);
          tmp[value] = {};
          console.log(value)
          for (const item of data.data[value]) {
            console.log(">",item)
            lab[item.date] = item.date;
            tmp[value][item.date] = item.count;
          }
        }
      }
      for (let [value] of Object.entries(lab)) {
        chartLabels.push(value);
      }
      for (const [key] of Object.entries(this.barDataStats[i].datasets)) {
            let arr=Array(chartLabels.length).fill(0);
            this.barDataStats[i].datasets[key].data = arr;
      }
      for (const [value] of Object.entries(name)) {
     // console.log(value,"<=")
      let arr = [];
    //  console.log("count_free_history_practice_source_0",tmp["count_free_history_practice_source_0"])
    // console.log("CL",chartLabels)

        chartLabels = chartLabels.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });

      for (const it in chartLabels) {
        if (tmp[name[value]][chartLabels[it]]) {
          arr.push(tmp[name[value]][chartLabels[it]]);
        }else{
          arr.push(0);
        }
      }
        const kn = this.barDataStats[i].datasets.findIndex(x => x.key === name[value]);
       // console.log(kn, name[value])
        if (kn>=0) {
          this.barDataStats[i].datasets[kn].data = arr;
        }
      }
      for (let [key,value] of Object.entries(chartLabels)) {
        if (this.statRange != null && this.statRange.value == "months") {
          let x = value.split('-');
          if (x.length >= 2) {
            x[1] = this.months[x[1] - 1]
            value = x.join(" ")
          }
          chartLabels[key]=value;
        }
      }
      //console.log( this.barDataStats[i],"count_free_history_practice_source_0")

      this.barDataStats[i].labels = chartLabels;
    },

    drawChartUser(data){
      let chartLabels=[];
      let lab = {};
      let name = [];
      let tmp = {};

        for (const [key] of Object.entries(this.barData.datasets)) {
          this.barData.datasets[key].data=[];
        }

      for (const [value] of Object.entries(data.data)) {
        if(data.data[value] == null){
          continue;
        }
        name.push(value);
        tmp[value]={};
        for(const item of data.data[value]) {
          lab[item.date]=item.date;
          tmp[value][item.date] =item.count;
        }
      }

      for (const [value] of Object.entries(lab)) {
        chartLabels.push(value);
      }
      chartLabels.sort();
     // console.log(chartLabels)
      for (const [value] of Object.entries(name)) {
        let arr = [];
        for (const it in chartLabels) {
          if (tmp[name[value]][chartLabels[it]]) {
            arr.push(tmp[name[value]][chartLabels[it]]);
          } else {
            arr.push(0);
          }
        }


        if (name[value] == "users") {
          this.barData.labels = lab;
          this.barData.datasets[0].data = arr;
        }
        if (name[value] == "apple"){
          this.barData.datasets[2]={
            label: 'Apple',
            backgroundColor: '#4BC0C0',
            data: arr
          }
        }

        if (name[value] == "google"){
          this.barData.datasets[3]={
            label: 'Google',
            backgroundColor: '#f97316 ',
            data: arr
          }
        }
        if (name[value] == "all"){
          this.barData.datasets[4]={
            label: 'Both Google & Apple',
            backgroundColor: '#2f4860',
            data: arr
          }
        }
      }
      let mail = [];
      for (let key = 0; key<=this.barData.datasets[0].data.length-1;key++) {
        mail.push(this.barData.datasets[0].data[key]-(this.barData.datasets[3].data[key]+this.barData.datasets[2].data[key] - this.barData.datasets[4].data[key]))
      }

      this.barData.datasets[1] = {
        label: 'Email',
        backgroundColor: '#FF6384 ',
        data: mail
      }

      for (let [key,value] of Object.entries(chartLabels)) {
        if ((this.statRangeUser != null && this.statRangeUser.value == "Month") || this.statRangeUser == null) {
          let x = value.split('-');
          if (x.length >= 2) {
            x[1] = this.months[x[1] - 1]
            value = x.join(" ")
          }
          chartLabels[key]=value;
        }
      }
      this.barData.labels = chartLabels;
    },
    fetchData(){
      let stat = [];
     // const colors = this.colors;
      this.dashboardService.getGeoStats('{}').then(data => {
        if(data==undefined || data.data==undefined){
          return;
        }
        let d = [];
        this.geoMaps = data.data;
        data.data[this.keyType].forEach(function (item) {
          if (item.code) {
            d.push(item);
          }
        });
        this.geo = d;
        stat["labels"] = [];
        stat["datasets"]={};
        stat["datasets"]["data"] = [];
        stat["datasets"]["backgroundColor"] = [];
        //stat["datasets"]["hoverBackgroundColor"] = [];
        let other = 0;
        let gd={};
        let av = [];

        data.data[this.keyType].forEach(function (item) {
          if (item.id==0 || item.id == null){
             other+=item.count;
          }else {
            av.push({"name":item.country,"code":item.code,"id":item.id})
            gd[item.code] = item.count;
          }
        //  stat["datasets"]["hoverBackgroundColor"].push(color);
        });

        this.other = other;
        this.geoData = gd;
        this.autoValue = av;
        this.autoValueUser = av;
        this.autoValueMap = av;
        if (other<=-1){
          stat["labels"].push("Other");
          stat["datasets"]["data"].push(other);
          //stat["datasets"]["backgroundColor"].push(cstaticColors[0]);
        }
        this.statsChart = {labels:  stat["labels"],  datasets: [
            {
              label: 'User statistics by country',
              data: stat["datasets"]["data"],
              backgroundColor:stat["datasets"]["backgroundColor"] ,

            },
          ]};
         this.autoValue.sort((a, b) => (a.name > b.name) ? 1 : -1);
      });

      this.dashboardService.getStats().then(data => {
        if(data==undefined || data.data==undefined){
          return;
        }

        let lt = [];
        data.data['letters'].forEach(item=> {
          if (this.autoValueLetter == null){
            this.autoValueLetter = {"label":item.title,"code":item.crc,"id":item.id};
            this.autoValueLetterUns = {"label":item.title,"code":item.crc,"id":item.id};
          }
          lt.push({"label":item.title,"code":item.crc,"id":item.id})
        });
        this.autoFilteredValueLetter = lt;

        this.v3 = data.data.v_3;
        this.trial = data.data.trial;
        this.stats = data.data.data_1;
        let dt = [];
        let lb = [];
        let cl = [];
        data.data.data_3.forEach((item,k)=> {
          if (!item.date){
            item.date = "unknown";
          }
          cl.push(this.colorArray[k]);
          lb.push(item["date"])
          dt.push(item["count"])


        });
        this.fwData.datasets[0].hoverBackgroundColor=cl;
        this.fwData.datasets[0].backgroundColor=cl;
        this.fwData.datasets[0].data=dt;
        this.fwData.labels=lb;

        this.pieData.datasets[0].data=[this.stats.ios, this.stats.android];
        this.pieDataUsers.datasets[0].data=[this.stats.all - this.stats.promo - this.stats.paid,this.stats.paid, this.stats.promo];

        this.pieDataRead.datasets[0].data=[data.data.mass_mailing.read, data.data.mass_mailing.count - data.data.mass_mailing.read];
        this.pieDataUnsubscribe.datasets[0].data=[data.data.mass_mailing.unsubscribe, data.data.mass_mailing.all-data.data.mass_mailing.unsubscribe];

        this.stats.pc_online = (this.stats.online/this.stats.all)*100;
        this.stats.pc_day = this.round((this.stats.per_day/this.stats.all)*100);
        this.stats.pc_week = this.round((this.stats.per_week/this.stats.all)*100);
        this.stats.pc_mouth = this.round((this.stats.per_mouth/this.stats.all)*100);
        this.stats.pc_year = this.round((this.stats.per_year/this.stats.all)*100);
        this.stats.rpc_day = this.round((this.stats.rper_day/this.stats.all)*100);
        this.stats.rpc_week = this.round((this.stats.rper_week/this.stats.all)*100);
        this.stats.rpc_mouth = this.round((this.stats.rper_mouth/this.stats.all)*100);
        this.stats.rpc_year = this.round((this.stats.rper_year/this.stats.all)*100);
        let lx =[];

        let tmpDat = [];
        let labels = [];
        let dat = [];
        let m = this.months;
        data.data.data_2.forEach(function (item) {
          tmpDat[item.year+(item.month/100)] = item;
          lx.push(item.year+(item.month/100));
        });
        const ld = lx.sort();
        for(let i=0;i<=ld.length-1;i++){
          labels.push(m[tmpDat[ld[i]].month-1]+"-"+tmpDat[ld[i]].year);
          dat.push(tmpDat[ld[i]].count);
        }

        this.barData = {
          labels: labels,
          datasets: [
            {
              label: 'Total',
              backgroundColor: '#00bb7e',
              data: dat
            },
          ]
        },

            this.dashboardService.getStatsInterval({}).then(data => {
              for(let i=0;i<=this.barDataStats.length-1;i++) {
                this.drawChart(data, i);
              }

            })

        this.dashboardService.getStatsInterval({"only_users":"1","range":"months"}).then(data => {
          this.drawChartUser(data);
        })
            this.barDataStats =[
              {
              datasets: [
                {
                  label: 'Number of shots at Free Shooting',
                  backgroundColor: this.colorArray[6],
                  data:[],
                  key:"sum_free_history_practice",
                },
                {
                  label: 'Passed Free Shooting (SG Timer)',
                  backgroundColor: this.colorArray[11],
                  data:[],
                  key:  "count_free_history_practice_source_0",
                },
                {
                  label: 'Passed Free Shooting (In App)',
                  backgroundColor: this.colorArray[12],
                  data:[],
                  key:  "count_free_history_practice_source_1",
                },

              ]
            },
              {
                datasets: [
                  {
                    label: 'Created dry practices',
                    backgroundColor: this.colorArray[1],
                    data:[],
                    key: "drill_dry_practice",
                  },
                  {
                    label: 'Passed Dry Practice "Classic" (SG Timer)',
                    backgroundColor: this.colorArray[5],
                    data:[],
                    key: "sum_drill_dry_history_source_0_classic_1",
                  },
                  {
                    label: 'Passed Dry Practice "Classic" (In App)',
                    backgroundColor: this.colorArray[6],
                    data:[],
                    key: "sum_drill_dry_history_source_1_classic_1",
                  },{
                    label: 'Passed Dry Practice "Smart" (SG Timer)',
                    backgroundColor: this.colorArray[7],
                    data:[],
                    key: "sum_drill_dry_history_source_0_classic_0",
                  },
                  {
                    label: 'Passed Dry Practice "Smart" (In App)',
                    backgroundColor: this.colorArray[8],
                    data:[],
                    key: "sum_drill_dry_history_source_1_classic_0",
                  },

                ]
              },
              {
                datasets: [
                  {
                    label: 'Created challenges',
                    backgroundColor: this.colorArray[2],
                    data:[],
                    key: "challenge",
                  },

                  {
                    label: 'Passed Challenge (SG Timer)',
                    backgroundColor: this.colorArray[9],
                    data:[],
                    key: "count_user_challenge_history_source_0",
                  },
                  {
                    label: 'Passed Challenge (In App)',
                    backgroundColor: this.colorArray[10],
                    data:[],
                    key: "count_user_challenge_history_source_1",
                  },

                ]
              },
              {
                datasets: [
                  {
                    label: 'Number of shots on Stage',
                    backgroundColor: this.colorArray[4],
                    data:[],
                    key:"sum_drill_shoot_history",
                  },
                  {
                    label: 'Number of shots at Free Shooting',
                    backgroundColor: this.colorArray[10],
                    data:[],
                    key:"sum_free_history_practice",
                  },
                  {
                    label: 'Number of shots fired during Challenge',
                    backgroundColor: this.colorArray[16],
                    data:[],
                    key: "sum_user_challenge_history",
                  },
                  {
                    label: 'Number of shots at Dry Practice',
                    backgroundColor: this.colorArray[15],
                    data:[],
                    key: "sum_drill_dry_history",
                  },
                  {
                    label: 'Total number of shots',
                    backgroundColor: this.colorArray[13],
                    data:[],
                    key: "total",
                  },
                ]
              },
              {
                datasets: [
                  {
                    label: 'Created stages',
                    backgroundColor: this.colorArray[0],
                    data:[],
                    key: "drill_shoot_practice",
                  },
                  {
                    label: 'Passed Stage (SG Timer)',
                    backgroundColor: this.colorArray[3],
                    data:[],
                    key: "count_drill_shoot_history_source_0",
                  },
                  {
                    label: 'Passed Stage (In App)',
                    backgroundColor: this.colorArray[4],
                    data:[],
                    key: "count_drill_shoot_history_source_1",
                  },
                ]
              },
              {
                datasets: [
                  {
                    label: 'Score sheets',
                    backgroundColor: this.colorArray[18],
                    data:[],
                    key: "score",
                  },

                  {
                    label: 'Score Sheet Stage',
                    backgroundColor: this.colorArray[20],
                    data:[],
                    key: "scores_0",
                  },

                  {
                    label: 'Score Sheet Challenge',
                    backgroundColor: this.colorArray[21],
                    data:[],
                    key: "scores_1",
                  },
                ]
              },
              {
                datasets: [
                  {
                    label: 'Synced emails',
                    backgroundColor: "#4BC0C0",
                    data:[],
                    key: "sum_emails",
                  },
                ]
              },
            ]


    });

    },
	},
	components: {
    'MapChart' : MapChart,
  }
}
</script>
<style>
.stoke{
  stroke: transparent;
}
</style>