import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/DashboardMain.vue';
import guest from './middleware/guest'
import auth from './middleware/auth'
import store from './store'
import middlewarePipeline from './router/middlewarePipeline'
import Chat from "./components/ChatItem";

window.api_url='https://stage.shooters.global/api/v1';
window.api_url_login='https://stage.shooters.global/login';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/LoginPage.vue'),
        meta: {
            middleware: [
                guest
            ]
        },
    },

    {
        path: '/user',
        name: 'users',
        component: () => import('./pages/CrudUsers.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },

    {
        path: '/abuse',
        name: 'abuse',
        component: () => import('./pages/CrudAbuse.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },

    {
        path: '/firmware',
        name: 'firmware',
        component: () => import('./pages/CrudFirmware.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/challenge',
        name: 'challenge',
        component: () => import('./pages/CrudChallenge.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/shared-practice',
        name: 'shared-practice',
        component: () => import('./pages/CrudSharedPractice.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/promo-code',
        name: 'promo-code',
        component: () => import('./pages/CrudPromoCode.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/gift-code',
        name: 'gift-code',
        component: () => import('./pages/CrudGiftCode.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/news-feed',
        name: 'news-feed',
        component: () => import('./pages/CrudNewsFeed.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/blog',
        name: 'blog',
        component: () => import('./pages/CrudBlog.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/mass-mailing',
        name: 'mass mailing',
        component: () => import('./pages/MassMailing.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },

    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('./pages/CrudNotifications.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },

    {
        path: '/dry-practice',
        name: 'dry-practice',
        component: () => import('./pages/CrudDryPractice.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },

    {
        path: '/dry-practice-templates',
        name: 'dry-practice-templates',
        component: () => import('./pages/CrudDryPracticeTemplates.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/logs',
        name: 'logs',
        component: () => import('./pages/CrudLogs.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/trash',
        name: 'trash',
        component: () => import('./pages/CrudTrash.vue'),
        meta: {
            middleware: [
                auth
            ]
        },
    },
    {
        path: '/chat',
        name: 'chat',
        component: Chat,
        meta: {
            middleware: [
                auth
            ]
        },
    },


];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router;
