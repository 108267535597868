import axios from 'axios';

export default class UserService {

	USER_TYPE_SHOOTER = 'Shooter';
	USER_TYPE_PREMIUM_SHOOTER = 'Premium';
	USER_TYPE_INSTRUCTOR = 'Instructor';
	USER_TYPE_ORGANIZER = 'Organizer';
	USER_TYPE_SHOP = 'Shop';
	USER_TYPE_SHOOTING_RANG = 'Rang';
	USER_TYPE_PRODUCERS = 'Producer';

	getUsers(event) {
		if (event.sortField == "location"){
			event.sortField = "_country_id";
		}
		if (event.sortField == "U.last_activity"){
			event.sortField = "last_activ";
		}
		return axios
			.post(process.env.VUE_APP_API_URL+'/user/list', JSON.stringify(event))
			.then(data=>data)
			.catch(error=>error);
	}

	setUser(user) {
		let uri = ""
		if (user.id) {
			uri = process.env.VUE_APP_API_URL+'/user/edit/' + user.id;
		}else {
			uri = process.env.VUE_APP_API_URL+'/user/add';
		}
		console.log(user)
		if (user.profile_photo){
			if (user.profile_photo.includes('/')){
				const a = user.profile_photo.split('/');
				user.profile_photo = a[a.length-1];
			}
		}
		if(user.profile_photo == "broken-1.jpg" || user.profile_photo == 'img.png'){
			delete user.profile_photo;
		}
			return axios
				.post(uri, JSON.stringify(user))
				.then(data=>data)
				.catch(error=>error);

	}

	delete(id) {
			return axios
				.post(process.env.VUE_APP_API_URL+'/user/delete/' + id, "");
	}

	deleteFull(id) {
		return axios
			.delete(process.env.VUE_APP_API_URL+'/user/' + id)
			.then(data=>data)
			.catch(error=>error);
	}

	getCountries(type = '') {
		return axios
			.get(process.env.VUE_APP_API_URL+'/geo/countries'+type)
			.then((response) =>  {
			if (response.status == 200) {
				let ret = [];
				for (let Key in response.data) {
					ret.push({label: response.data[Key].name, value: response.data[Key].id});
				}
				return ret;
			}
			return null;
		});
	}

	getRegions(value) {
		return axios
			.get(process.env.VUE_APP_API_URL+'/geo/regions?country_id=' + value)
			.then((response)=> {
			if (response.status == 200){
				let ret = [];
				for (let Key in response.data) {
					ret.push({label: response.data[Key].name, value: response.data[Key].id});
				}
				return ret;
			}
			return null;
		});
	}

	getCity(value) {
		return axios
			.get(process.env.VUE_APP_API_URL+'/geo/cities?region_id=' + value)
			.then((response)=> {
			if (response.status == 200){
				let ret = [];
				for (let Key in response.data) {
					ret.push({label: response.data[Key].name, value: response.data[Key].id});
				}
				return ret;
			}
			return null;
		});
	}

	setTrialActive(item) {
		return axios
			.get(process.env.VUE_APP_API_URL+"/trial/activate/"+item)
			.then(data=>data)
			.catch(error=>error);
	}

	setTrialUnActive(item) {
		return axios
			.get(process.env.VUE_APP_API_URL+"/trial/deactivate/"+item)
			.then(data=>data)
			.catch(error=>error);
	}

	setTrial(item) {
		return axios
			.post(process.env.VUE_APP_API_URL+"/trial/save", JSON.stringify(item))
			.then(data=>data)
			.catch(error=>error);
	}

	activatePromoCode(userid, type) {
		return axios
			.get(process.env.VUE_APP_API_URL+"/activate/"+userid+"/"+type)
			.then(data=>data)
			.catch(error=>error);
	}

	deletePromoCode(item) {
		return axios
			.delete(process.env.VUE_APP_API_URL+"/activate/"+item)
			.then(data=>data)
			.catch(error=>error);
	}

}
