import axios from 'axios';

export default class ChatService {
	errorHandler(error){
		if (error.response) {
			return  error.response
		} else if (error.request) {
			return  error.request;
		} else {
			return  error.message;
		}
	}
	createDialog(id) {
		return axios
			.get(process.env.VUE_APP_API_URL_API_MAIN+'/chat/create-dialog/'+id)
			.then(response => response).catch(err => this.errorHandler(err));
	}
	getSubscribers(){
		return axios
			.get(process.env.VUE_APP_API_URL_API_MAIN+'/chat/subscriptions')
			.then(response => response).catch(err => this.errorHandler(err));
	}
	sendMessage(data,key,gallery=[]){
		let tmp = {key_channel:key,body:data}
		if(gallery.length>0){
			tmp["gallery"] = gallery;
		}

		return axios
			.post(process.env.VUE_APP_API_URL_API_MAIN+'/chat/send',JSON.stringify(tmp))
			.then(response => response).catch(err => this.errorHandler(err));
	}
	getInfo(id) {
		return axios
			.get(process.env.VUE_APP_API_URL_API_MAIN+'/user/by-id/'+id)
			.then(response => response).catch(err => this.errorHandler(err));
	}
	getHistory(id){
		return axios
			.get(process.env.VUE_APP_API_URL_API_MAIN+'/chat/history/'+id)
			.then(response => response).catch(err => this.errorHandler(err));
	}
	addToDialog(id){
		return axios
			.get(process.env.VUE_APP_API_URL_API_MAIN+'/chat/create-dialog/'+id)
			.then(response => response).catch(err => this.errorHandler(err));
	}
	removeDialog(id){
		return axios
			.get(process.env.VUE_APP_API_URL_API_MAIN+'/chat/unsubscribe/'+id)
			.then(response => response).catch(err => this.errorHandler(err));
	}
	presenceStats(ids){
		return axios
			.post(process.env.VUE_APP_API_URL_API_MAIN+'/chat/presence-stats',JSON.stringify(ids))
			.then(response => response).catch(err => this.errorHandler(err));
	}

}
