<template>
  <div class="grid">
    <Toast/>
  </div>

  <div class="grid p-fluid">
    <Dialog v-model:visible="userDialog" :style="{width: '650px'}" header="Media loader" :modal="true" class="p-fluid">
      <div class="grid">
        <div class="col-12">
          <div class="card">
            <h5>Advanced</h5>
            <FileUpload
                name="files[]"
                ref="upload"
                id="file"
                :auto="false"
                :url = getUri()
                @upload="compliteUpload"
                @error="onErrUpload"
                :multiple="true"
                :maxFileSize="1000000000"/>
          </div>
        </div>
      </div>
    </Dialog>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="border-round border-1 surface-border p-4 mr-3 mb-2 bg-white">
        <div width="p-fluid flex" height="30px" >
          <MultiSelect @change="addToDialog" v-model="multiselectValueLeft" :options="multiselectValuesLeft" optionLabel="name" placeholder="Select Users" :filter="true">
            <template #value="slotProps">
              <div class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2" v-for="option of slotProps.value" :key="option.id">
                <span v-if="option.country!=null" :class="'mr-2 flag flag-' + option.country.code.toLowerCase()" style="width:18px; height: 12px"/>
                <div>{{option.name}}</div>
              </div>
              <template v-if="!slotProps.value || slotProps.value.length === 0">
                <div class="p-1">Add users to dialog</div>
              </template>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <span v-if="slotProps.option.country!=null" :class="'mr-2 flag flag-' + slotProps.option.country.code.toLowerCase()" style="width:18px; height: 12px"/>
                <div>{{slotProps.option.name}}</div>
              </div>
            </template>
          </MultiSelect>

        </div>
      </div>



     <scroll-panel style="height: calc(100vh - 280px);">
     <div  v-for="(item) in userChat" :key="item.id">
       <ContextMenu :model="contextItems"  ref="menu"/>
      <div @contextmenu="onElementRightClick" class="mb-1"  v-bind:class ="(item.id == selectLeft)?'bg-gray-300':'bg-white'" @click="setLeftSelect(item.id)">
        <div class="border-round border-1 surface-border p-1">
          <div class="flex mb-1">
            <img shape="circle" size="4rem" class="mr-1" :src="item.chat_avatar" style="vertical-align: middle; border-radius: 50%;"  v-if="item.chat_avatar" width="64" height="64"  @error="item.chat_avatar ='images/broken-1.png'"/>
            <div>
              <div width="10rem" class="mb-1">{{item.name}}</div>
              <div width="5rem" class="mb-1">{{item.key}}</div>
              <div height=".5rem">
                <div className="typing" v-if="item.isTyping">
                  <div className="typing__dot"></div>
                  <div className="typing__dot"></div>
                  <div className="typing__dot"></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
          </div>
      </scroll-panel>

      <div class="p-fluid mt-auto bg-white">
        <div class="border-round border-1 surface-border" style="height:100px">

            <div class="p-inputgroup grid">
              <div class="col-12 md:col-4">
               <Button icon="pi pi-users"  v-bind:style="[this.currentType == 0 ? 'color:#6366F1;background:#fff;'  : '']"  class="align-self-center p-button-rounded mr-2 mb-2 mt-2 ml-6" @click="changeType(0)" />
              </div>
              <div class="col-12 md:col-4">
               <Button icon="pi pi-comments" v-bind:style="[this.currentType == 1 ? 'color:#6366F1;background:#fff;'  : '']"   class="align-self-center p-button-rounded mr-2 mb-2 mt-2 ml-6" @click="changeType(1)" />
            </div>
              <div class="col-12 md:col-4">
               <Button icon="pi pi-cog" v-bind:style="[this.currentType == 2 ? 'color:#6366F1;background:#fff;'  : '']"  class="align-self-center p-button-rounded mr-2 mb-2 mt-2 ml-6" @click="changeType(2)" />
            </div>
            </div>


        </div>
        <div class="p-fluid mt-auto bg-white">
        </div>
      </div>

    </div>


    <div class="col-12 lg:col-6 xl:col-9">
      <div class="border-round border-1 surface-border p-4 mr-3 mb-2 bg-white">
        <div width="p-fluid flex" height="30px">
          <MultiSelect  v-model="multiselectValueRight" :options="multiselectValuesRight" optionLabel="name" placeholder="Select Users" :filter="true">
            <template #value="slotProps">
              <div class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2" v-for="option of slotProps.value" :key="option.id">
                <span v-if="option.country!=null" :class="'mr-2 flag flag-' + option.country.code.toLowerCase()" style="width:18px; height: 12px"/>
                <div>{{option.name}}</div>
              </div>
              <template v-if="!slotProps.value || slotProps.value.length === 0">
                <div class="p-1">Add users to a chat</div>
              </template>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <span v-if="slotProps.country!=null" :class="'mr-2 flag flag-' + slotProps.country.code.toLowerCase()" style="width:18px; height: 12px"/>
                <div>{{slotProps.option.name}}</div>
              </div>
            </template>
          </MultiSelect>

        </div>
      </div>






      <scroll-panel class="mb-2"  ref="scrolling"  style="height: calc(100vh - 280px);" v-if="this.chatKey!=null" >

        <div class="bg-white p-fluid" v-for="(item) in this.messages[this.chatKey]" :key="item" :avatar="this.getName(item.user_id).avatar" >

          <div class="border-round border-1 surface-border p-4" @contextmenu="onElementRightClick">

            <div class="flex mb-3">
              <img shape="circle" size="4rem"  class="mr-1" :src="avatar" style="vertical-align: middle; border-radius: 50%;"  v-if="avatar" width="64" height="64"  @error="avatar ='images/broken-1.png'"/>
              <div>

                <div width="5rem" class="mb-2">{{ this.getName(item.user_id).name }}</div>
                <div height=".5rem">{{item.time}}</div>
              </div>
            </div>
            <div class="flex mb-3">
            <div width="100%" height="100px"  class="ml-7 pl-2" >{{item.input}}</div>
            </div>
            <!--
            <div class="flex justify-content-between mt-3">
              <Skeleton width="4rem" height="2rem"></Skeleton>
              <Skeleton width="4rem" height="2rem"></Skeleton>
            </div>
            -->
          </div>


        </div>
      </scroll-panel>
      <scroll-panel>
      <div class="p-fluid mt-auto bg-blue-300">
          <div class="border-round border-1 surface-border p-4">
            <div width="p-fluid flex" height="50px" >
              <div class="p-inputgroup">
                  <Button icon="pi pi-file"  class="mr-2" @click="showDialog" />
                  <InputText placeholder="Some text..." v-model="body" v-on:keyup="canType"/>
                  <Button icon="pi pi-send"  class="p-button-rounded mr-2 mb-2 ml-2" @click="sendMessage" />

                </div>
            </div>
          </div>
        <div class="p-fluid mt-auto bg-white">
          <div style="width:480px;">
            <vueper-slides  class="no-shadow"
                            :autoplay="false"
                            :bullets="false"
                            :visible-slides="3"
                            slide-multiple
                            :gap="3"
                            :slide-ratio="1 / 4"
                            :dragging-distance="200">

              <vueper-slide  openInNew="true"
                             v-for="(slide, i) in this.gallery"
                             :key="i"
                             :link="slide.file"
                             :image="slide.file"
              />

            </vueper-slides>

          </div>

        </div>
        </div>
      </scroll-panel>
    </div>


  </div>


</template>

<script>

import UserService from "../service/UserService";
import ChatService from "../service/ChatService";
import Centrifuge from "centrifuge";
import store from "../store";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      contextItems: [
        {
          label:'Mark is read',
          icon:'pi pi-fw pi-check-circle'
        },
        {
          label:'Pin',
          icon:'pi pi-fw pi-paperclip'
        },
        {
          label:'Delete',
          icon:'pi pi-fw pi-trash'
        }
      ],

      menuModel: [
        {label: 'View', icon: 'pi pi-fw pi-search', command: () => this.viewProduct(this.selectedProduct)},
        {label: 'Delete', icon: 'pi pi-fw pi-times', command: () => this.deleteProduct(this.selectedProduct)}
      ],
      imgUrl:"https://api.stage.shooters.global/api/web/v3/images/user/",
      gallery: [],
      selectedItem:null,
      userDialog: false,
      timer: null,
      myId:null,
      canPublish:true,
      throttleTime : 500,
      currentType:0,
      typing: [],
      knownUser:[],
      knownUserInfo:[],
      core: null,
      chatKey: null,
      body:"",
      messages:[],
      selectLeft: 0,
      //currentMessages:[],
      currentChannel:null,
      userChat: [],
      userService: null,
      chatService: null,
      multiselectValueLeft: null,
      multiselectValuesLeft: [],
      multiselectValueRight: null,
      multiselectValuesRight: [],
      chatChannels:[],
    }

  },

  created() {
    this.userService = new UserService();
    this.chatService = new ChatService();

  },
  mounted() {
      this.timer = setInterval(() => {
        this.typing.forEach((v,k) => {
            if (Date.now() - v.time > 5000) {
              const x = this.userChat.findIndex(p=> p.recipient_id == v.id || v.id == p.user_id);
              if(x>=0 && this.userChat[x] && this.userChat[x].isTyping){
                this.userChat[x]["isTyping"] = false;
              }
              this.typing.splice(k ,1)
            }
        })
        }, 1000)

    this.getSubscribers();
    this.fetchData();
    setInterval(() => {
      this.timer -= 1
    }, 50000)
  },
  unmounted() {
   this.disconnectWSS();
   clearInterval(this.timer)
  },


methods: {
  userClick(event){
    console.log(event)
  },
  onElementRightClick(event) {
    this.$refs.menu.show(event);
  },
  viewProduct(product) {
    this.$toast.add({severity: 'info', summary: 'Product Selected', detail: product.name});
  },
  deleteProduct(product) {
   if (product){
     console.log(product)
   }
  },
  formatCurrency(value) {
    console.log(value)
  },
  onRowContextMenu(event) {
    this.$refs.cm.show(event.originalEvent);
  },
  getUri(){
      return process.env.VUE_APP_API_URL_API_MAIN+'/chat/upload-multimedia';
  },
  compliteUpload(e){
    if(e.xhr!=undefined && e.xhr.data !=undefined && e.xhr.data.data!=undefined){
      this.gallery.push(e.xhr.data.data);
    }
    this.userDialog = false;
  },
  onErrUpload(e){
    if(e.xhr!=null && e.xhr.response!=null && e.xhr.response.error!=null){
      this.messUpload("error",e.xhr.response.status,e.xhr.response.error)
    }
  },
  messUpload(sr,sum,detail){
    this.$toast.add({severity: sr, summary: sum, detail: detail, life: 10000});
  },

  showDialog(){
    this.userDialog = true;
  },
  parseJwt (token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));

    return JSON.parse(jsonPayload);
  },
    canType(){
      if(this.canPublish) {
        this.core.publish(this.currentChannel,{typing: this.myId})
        this.canPublish = false;
        setTimeout(()=> {
           this.canPublish = true;
        }, this.throttleTime);
      }
   },

  changeType(v){
    this.currentType = v;
  },
  addToDialog(){
   if(this.multiselectValueLeft!=null && this.multiselectValueLeft.length>0){
     const e = this.multiselectValueLeft[this.multiselectValueLeft.length-1]
     let x = this.userChat.find(p => p.recipient_id == e.id);
     if(x == undefined) {
       this.chatService.addToDialog(e.id).then(() => {
         this.getSubscribers();
       })
     }else{
       this.chatService.removeDialog(x.id).then(() => {
         this.getSubscribers();
       })
     }
   }
  },
    getHistory(id,messId){
      let mx = [];
      this.chatService.getHistory(id)
      .then(res=>{
        if(res.data && res.data.data) {
          res.data.data.forEach(item => {
            this.updateMessageArray(JSON.parse(item.body), messId, false).then(news=>{
              if(news) {
                mx.push(news);
              }
            })
          })
        }
      }).then(() => {
        let em=[];
            mx.forEach(elem => {
              if(elem){
                em[elem.user_id] = elem;
              }
              //t
            })
        em.forEach(val=>{
          this.resolveUser(val);
        })
          }
      )

    },
    getName(id){
      return this.knownUserInfo.find(p => p.id == id);
    },
    disconnectWSS(){
      if(this.core!=null && this.core!=undefined){
        this.core.disconnect();
      }
    },
    connectWSS(token){
      this.core = new Centrifuge(process.env.VUE_APP_WSS_CHAT + "/connection/websocket");
      this.core.setToken(token);
      this.core.connect();
      const payload = this.parseJwt(token)
      this.myId = payload.user_id;
    },
    sendMessage(){
      if(this.chatKey !=null && (this.body!="" || this.gallery.length>0)){
        this.chatService.sendMessage(this.body, this.chatChannels[this.chatKey],this.gallery)
            .then(res=>{
              console.log('successfully published',res);
              this.body = "";
              this.gallery=[];
            })
            .catch(err=>{
              console.log('publish error', err);
            })
        }
    },
    setLeftSelect(id){
      this.selectLeft = id;
      for(const key in this.userChat) {
        const item = this.userChat[key];
        if (item.id != null && item.id == id) {
         // this.currentMessages = this.messages[key];
          this.chatKey = key;
          this.currentChannel = item.key;
        }
      }
      this.$nextTick(() => {
        this.$refs.scrolling.$el.children[0].children[0].scrollTop = this.$refs.scrolling.$el.children[0].children[0].scrollHeight;
      })

    },

    getSubscribers() {
      this.chatService.getSubscribers().then(response=>{
        if(response.status == 200) {
          if(this.core == null || this.core == undefined){
            this.connectWSS(store.state.user.token);
            this.core.on('connect', function(ctx) {
              console.log("connected", ctx);
            });

            this.core.on('disconnect', function(ctx) {
              console.log("disconnected", ctx);
            });
          }
          const items = [];
          const mess = [];
          const keys = [];
            response.data.forEach(item=> {
              if(item.id!=null) {
                items.push(item);
                mess.push([]);
                keys.push(item.key);
                this.getHistory(item.id, mess.length-1);
              }
          })
          for(let i=0;i<=keys.length-1;i++){
            this.core.subscribe(keys[i], (ctx)=> {
              if (ctx.data!=undefined) {
                this.updateMessageArray(ctx.data, i);
              }
            });
          }
          this.chatChannels = keys;
          this.messages = mess;
          this.userChat = items;
        }
      })
    },
    createMess(data,act){
      if(data!=null && data.status == 200){
        this.$toast.add({severity:'success', summary: 'Successful', detail: 'item '+act, life: 3000})
      }
      if(data!=null && data.status != 200){
        this.$toast.add({severity: 'error', summary: data.data.field, detail: data.data.message, life: 3000})
      }
    },

    createDialog(id) {
        this.chatService.createDialog(id).then(response=>{
          this.createDialog(response);
        })
    },
    resolveUser(newMessage){
      if (!this.knownUser.includes(newMessage.user_id)) {
        this.chatService.getInfo(newMessage.user_id).then(res => {
          if(!this.knownUser.includes(res.data.id)) {
            const tmp = res.data.id;
            this.knownUser.push(tmp);
            this.knownUserInfo.push(res.data);
          }
        })
      }
    },
    fetchData() {
      let users = [];
      const imgUrl = this.imgUrl;
      this.userService.getUsers({first: 0, rows: 10, sortField: "id", sortOrder: 1}).then(response=> {
        response.data.data.forEach(itemTmp=> {
          const item = itemTmp.User;
          let name ="";
          let tmp =[];
          if(item.name!=null){
            tmp.push(item.name);
          }
          if(item.lastname!=null){
            tmp.push(item.lastname);
          }
          name = tmp.join(" ");
          if(name === "" && item.phone!=null){
            name = item.phone;
          }
          if(name === "" && item.email!=null){
            name = item.email;
          }
          item.name = name;
          if(item.profile_photo!=""){
            item.profile_photo = imgUrl+"/"+item.id+"/"+item.profile_photo;
          }
          users.push(item);
        })
      });
      this.multiselectValuesLeft = users;
      this.multiselectValuesRight = users;
    },
    async updateMessageArray(newMessage, i, b = true) {
      if(newMessage.typing!=undefined && newMessage.typing !=this.myId){
        if(!this.typing.find(p=> p.id == newMessage.typing)){
          this.typing.push({id: newMessage.typing, time: Date.now()})
          const k = this.userChat.findIndex(p=> p.recipient_id == newMessage.typing || p.user_id == newMessage.typing);
          if(this.userChat[k]!= undefined && !this.userChat[k].isTyping){
            this.userChat[k]["isTyping"] = true;
          }
        }else{
          const id = this.typing.findIndex(p=> p.id == newMessage.typing);
          if(id>=0){
            this.typing[id].time = Date.now();
          }
        }
      }

      const id = this.typing.findIndex(p=> p.id == newMessage.user_id);
      if(~id){
          if(Date.now() - this.typing[id]> 10000){
            this.typing.splice(id,1);
            const k = this.userChat.findIndex(p=> p.recipient_id == newMessage.user_id || newMessage.user_id == p.user_id);
            if(this.userChat[k].isTyping){
              this.userChat[k]["isTyping"] = false;
            }
          }
      }

      if(newMessage.time == undefined){
        return
      }

      const k = this.userChat.findIndex(p=> p.recipient_id == newMessage.user_id || newMessage.user_id == p.user_id);
      if(this.userChat[k].isTyping){
        const id = this.typing.findIndex(p=> p.id == newMessage.user_id);
        this.typing.splice(id,1);
        this.userChat[k]["isTyping"] = false;
      }
      newMessage.time =  new Date( newMessage.time * 1000).toLocaleString();
      this.messages[i].push(newMessage);
      if (b) {
        this.$nextTick(() => {
          this.$refs.scrolling.$el.children[0].children[0].scrollTop = this.$refs.scrolling.$el.children[0].children[0].scrollHeight;
        })
      }
      return newMessage;
    }
  },




}
</script>
<style>
.stoke{
  stroke: transparent;
}

.typing {
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

</style>