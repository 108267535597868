import axios from 'axios';

export default class DashboardService {

	getStats() {
		return axios.get(process.env.VUE_APP_API_URL+'/user/stats')
			.then(data=>data)
			.catch(error=>error);
	}
	getStatsInterval(data) {
		let q="";
		let x = [];
		if(data["range"]){
			x.push("range="+data["range"]);
			delete data["range"];
		}
		if(data["only_users"]){
			x.push("only_users=1")
		}
		if(x.length>0){
			q = "?"+x.join("&")
		}
		return axios
			.post(process.env.VUE_APP_API_URL+'/user/stats'+q, JSON.stringify(data))
			.then(data=>data)
			.catch(error=>error);

	}
	getGeoStats(data) {
		return axios
			.post(process.env.VUE_APP_API_URL+'/geo/stats', JSON.stringify(data))
			.then(data=>data)
			.catch(error=>error);
	}
	getCrcStats(data) {
		return axios
			.get(process.env.VUE_APP_API_URL+'/mail/stat/'+data)
			.then(data=>data)
			.catch(error=>error);
	}
	getCrcUnsubscribeStats(data) {
		return axios
			.get(process.env.VUE_APP_API_URL+'/mail/stat/unsubscribe/'+data)
			.then(data=>data)
			.catch(error=>error);
	}
}
